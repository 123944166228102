import React from 'react';

const About = () => (
  <div className="content-container">
    <div className="content-title-group not-found">
      <h2 className="title">Azure Platform Management Portal</h2>
      <p>
        This portal was created to support with functionalities to support OBOS on the Azure Platform.
      </p>
      <br />
      <h2 className="title">Resources</h2>
      <ul>
        <li>
          <a href="https://obos-bbl.atlassian.net/wiki/spaces/AI/overview">
            Azure Platform Confluence page
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default About;
